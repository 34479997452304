const reloadModalAssets = ['reloadModalBackground.png', 'reloadModalLogo.png'];
const generalUIAssets = ['generalUI.png', 'generalUI.json'];
const promotionAssets = ['promotionModalBackground.png', 'promotionModalText.fnt'];

export function getCustomAssetsForGame(gameName) {
  const customAssets = [...reloadModalAssets];

  switch (gameName) {
    case 'GoldOfEgypt':
    case 'GoldOfEgyptDice':
    case 'GoldOfEgypt90': {
      customAssets.push(
        ...generalUIAssets,
      );
      break;
    }
    case 'CherryBlast':
    case 'CherryBlastDice':
    case 'CherryXmass':
    case 'FishyGuy':
    case 'TolucaFortune': {
      customAssets.push(
        ...generalUIAssets,
        ...promotionAssets,
      );
      break;
    }
    default: {
      customAssets.push(
        'jackpotWin.mp3',
        'particleLine.png',
        'particleSpark.png',
        'progressScaleDefault.mp3',
        'progressMoveDefault.mp3',
        'progressWinDefault.mp3',
        'progressCompleteDefault.mp3',
        'reelBonusWaitDefault.mp3',
        'reelSpinDefault.mp3',
        'reelsMultiplierHit.mp3',
        ...promotionAssets,
      );
    }
  }

  return customAssets;
}
